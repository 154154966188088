import React from 'react'

import { Helmet } from 'react-helmet'

import './goodbye.css'

const Goodbye = (props) => {
  return (
    <div className="goodbye-container">
      <Helmet>
        <title>Heist - Goodbye</title>
        <meta property="og:title" content="Heist - Goodbye" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="goodbye-container1">
        <span className="goodbye-text">
          <span className="goodbye-text01">This doesn't have to be goodbye 🥺</span>
          <br className="goodbye-text02"></br>
          <br className="goodbye-text03"></br>
          <span className="goodbye-text04">
            Before you go, we'd love to hear your feedback!  Please tell us about your experience with <i>heist</i>, or report bugs by simply shaking your device while <i>heist</i> is open.  You can add screenshots and descriptions.  We value your feedback and constantly strive to build a better experience for our users.
          </span>
          <br className="goodbye-text06"></br>
          <br className="goodbye-text07"></br>
          <span className="heistFaqQuestion">So you really want to leave</span>
          <br className="goodbye-text09"></br>
          <br className="goodbye-text10"></br>
          <span className="goodbye-text11">
            While we don't want to see you go, we totally respect your choice.  To permanently delete your <i>heist</i> account, take the following steps inside the app:
            <ol>
              <li>Tap the profile icon on the bottom right of the home screen <br/><img height="100" src="/profileicon.png"/></li>
              <li>Tap the settings icon on the top right of the profile screen <img height="100" src="/settingsicon.png"/></li>
              <li>Tap <b>Account & Privacy</b></li>
              <li>Tap <b>Delete My Account</b></li>
            </ol>
            <br/>  
            This will present you with a warning.  If you confirm, you will be logged out immediately, and your account will be marked for deletion.  Your profile, takes, playlists, and comments will no longer be available to other users of <i>heist</i>.
          </span>
          <br className="goodbye-text12"></br>
          <br className="goodbye-text13"></br>
          <span className="heistFaqQuestion">
            Wait!  I made a mistake and I want to come back!
          </span>
          <br className="goodbye-text16"></br>
          <br className="goodbye-text17"></br>
          <span className="goodbye-text18">
            Good news!  When you delete your account, you'll have 30 days to restore it.  If you sign back in any time in that 30 day window, your account will be made active again, and will no longer be marked for deletion.
          </span>
          <br className="goodbye-text19"></br>
          <br className="goodbye-text20"></br>

          <br className="goodbye-text64"></br>
          <br className="goodbye-text65"></br>
        </span>
      </div>
    </div>
  )
}

export default Goodbye
