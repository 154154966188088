import React from 'react'

import { Helmet } from 'react-helmet'

import './heist-help.css'

const HeistHelp = (props) => {
  return (
    <div className="heist-help-container">
      <Helmet>
        <title>Heist - Help</title>
        <meta property="og:title" content="Heist - Help" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="heist-help-container1">
        <span className="heist-help-text">
          <span className="heist-help-text01">What is heist?</span>
          <br className="heist-help-text02"></br>
          <br className="heist-help-text03"></br>
          <span className="heist-help-text04">
            heist is a social audio platform where users can create, share, and
            listen to short-form audio content. From podcasts and music to
            personal audio logs and captured moments, our platform allows users
            to connect through the power of sound. Through our
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <a
            href="https://pro.heist.com"
            target="_blank"
            rel="noreferrer noopener"
            className="heist-help-link"
          >
            pro suite
          </a>
          <span className="heist-help-text05">
            , we also enable users to create excerpts of long-form audio content
            and seamlessly post it to the app.
          </span>
          <br className="heist-help-text06"></br>
          <br className="heist-help-text07"></br>
          <span className="heistFaqQuestion">How do I create an account?</span>
          <br className="heist-help-text09"></br>
          <br className="heist-help-text10"></br>
          <span className="heist-help-text11">
            To create an account, download the app from your respective app
            store, click on &quot;Sign Up,&quot; and follow the instructions.
            You can sign up using an email address, phone number, or through
            various social media accounts for convenience.
          </span>
          <br className="heist-help-text12"></br>
          <br className="heist-help-text13"></br>
          <span className="heistFaqQuestion">
            Can I create content if I don’t have any professional equipment?
          </span>
          <br className="heist-help-text15"></br>
          <br className="heist-help-text16"></br>
          <br className="heist-help-text17"></br>
          <span className="heist-help-text18">
            Absolutely! Our platform is designed for everyone, regardless of the
            equipment they own. You can start creating content with just your
            smartphone.
          </span>
          <br className="heist-help-text19"></br>
          <br className="heist-help-text20"></br>
          <span className="heistFaqQuestion">
            How do I find and follow other users?
          </span>
          <br className="heist-help-text22"></br>
          <br className="heist-help-text23"></br>
          <span className="heist-help-text24">
            Use the search function to discover users by their name or content.
            You can also explore content categories to find users who share
            content that interests you. Follow them to keep updated with their
            latest takes (posts).
          </span>
          <br className="heist-help-text25"></br>
          <br className="heist-help-text26"></br>
          <span className="heistFaqQuestion">
            Is there a limit to how much audio I can upload?
          </span>
          <br className="heist-help-text28"></br>
          <br className="heist-help-text29"></br>
          <span className="heist-help-text30">
            Currently, there is no limit to the amount of content you can
            upload. However, each audio recording is limited to 1 minute. You
            can share a lot in 60 seconds! Give it a try!
          </span>
          <br className="heist-help-text31"></br>
          <br className="heist-help-text32"></br>
          <span className="heistFaqQuestion">
            How can I share my audio content on other social media platforms?
          </span>
          <br className="heist-help-text34"></br>
          <br className="heist-help-text35"></br>
          <span className="heist-help-text36">
            Each take has a share button that generates a link which can be
            posted on other social media platforms, emailed, or sent through
            messaging apps. This makes it easy to share your favorite moments
            with friends outside the app.
          </span>
          <br className="heist-help-text37"></br>
          <br className="heist-help-text38"></br>
          <span className="heistFaqQuestion">
            Can I monetize my content on this platform?
          </span>
          <br className="heist-help-text40"></br>
          <br className="heist-help-text41"></br>
          <span className="heist-help-text42">
            Soon! We are working towards enabling content creators who meet
            certain criteria, such as follower count and engagement levels, to
            join our Partner Program to earn revenue from their content through
            ads, subscriptions, and donations.
          </span>
          <br className="heist-help-text43"></br>
          <br className="heist-help-text44"></br>
          <span className="heistFaqQuestion">
            How do I report inappropriate content or harassment?
          </span>
          <br className="heist-help-text46"></br>
          <br className="heist-help-text47"></br>
          <span className="heist-help-text48">
            User safety is our top priority. You can report inappropriate
            content or behavior by clicking the “Report” button on a user’s
            profile or directly on the content itself. Our team will review the
            report and take appropriate action.
          </span>
          <br className="heist-help-text49"></br>
          <br className="heist-help-text50"></br>
          <span className="heistFaqQuestion">
            Can I use the app without creating content?
          </span>
          <br className="heist-help-text52"></br>
          <br className="heist-help-text53"></br>
          <span className="heist-help-text54">
            Yes, heist is also a great place for pure listeners. You can explore
            and enjoy a wide range of audio content without ever needing to
            create content yourself.
          </span>
          <br className="heist-help-text55"></br>
          <br className="heist-help-text56"></br>
          <span className="heistFaqQuestion">
            Is there a way to get featured on the platform?
          </span>
          <br className="heist-help-text58"></br>
          <br className="heist-help-text59"></br>
          <span className="heist-help-text60">
            Also soon! We plan to regularly feature great content on our
            homepage and in special categories. To increase your chances of
            being featured, engage with your audience, produce high-quality
            content, and be active on the platform.
          </span>
          <br className="heist-help-text61"></br>
          <br className="heist-help-text62"></br>
          <br className="heist-help-text63"></br>
          <br className="heist-help-text64"></br>
          <br className="heist-help-text65"></br>
        </span>
      </div>
    </div>
  )
}

export default HeistHelp
