import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import HeistHelp from './views/en/heist-help'
import PrivacyPolicy from './views/en/privacy-policy'
import HeistStealAMinute from './views/heist-steal-a-minute'
import NotFound from './views/not-found'
import Eula from './views/en/eula'
import Goodbye from './views/en/goodbye'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={HeistHelp} exact path="/en/help" />
        <Route component={PrivacyPolicy} exact path="/en/privacy" />
        <Route component={Eula} exact path="/en/eula" />
        <Route component={HeistStealAMinute} exact path="/" />
        <Route component={Goodbye} exact path="/en/goodbye" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
