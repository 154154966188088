import React from 'react'

import { Helmet } from 'react-helmet'

import NavbarInteractive from '../components/navbar-interactive'
import './heist-steal-a-minute.css'

const HeistStealAMinute = (props) => {
  return (
    <div className="heist-steal-a-minute-container">
      <Helmet>
        <title>Heist - Steal A Minute</title>
        <meta property="og:title" content="Heist - Steal A Minute" />
        <meta
          property="og:image"
          content="https://imagedelivery.net/9aikPAPGcKmsSoXjsANPXA/b2cf1358-b831-49ec-9180-cef24d73cc00/1024w"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <NavbarInteractive></NavbarInteractive>
      <div className="heist-steal-a-minute-container1">
        <div className="heist-steal-a-minute-container2">
          <span className="heist-steal-a-minute-text heistHeroText">
            Listen; don&apos;t take our word for it
          </span>
        </div>
        <div className="heist-steal-a-minute-container3">
          <span className="heist-steal-a-minute-text1 heistHeroText-2">
            Sign up for early access to the public release.
          </span>
        </div>
        <div className="heist-steal-a-minute-container4">
          <span className="heist-steal-a-minute-text2">
            We’ll never share your email address.
          </span>
        </div>
        <div className="heist-steal-a-minute-container5">
          <iframe
            src="https://cdn.forms-content.sg-form.com/aa78f542-76e3-11ec-8556-6ee0fa21b988"
            className="heist-steal-a-minute-iframe"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

export default HeistStealAMinute
